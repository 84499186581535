<template>
  <form class="v-components forms-update-form" @submit.prevent="submit">
    <b-field grouped>
      <b-field
        label="Status"
        :type="{ 'is-danger': errors.has('status') }"
        :message="errors.first('status')"
      >
        <b-select
          data-vv-as="Status"
          name="status"
          v-model="formData.status"
          v-validate="'boolean|required'"
          placeholder="Select"
        >
          <option
            v-for="(status, key) in formValues.status"
            :value="status.code"
            :key="key"
          >
            {{ status.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped>
      <b-field
        label="Name"
        :type="{ 'is-danger': errors.has('name') }"
        :message="errors.first('name')"
      >
        <b-input
          type="text"
          v-model="formData.name"
          name="name"
          data-vv-as="Name"
          v-validate="'required'"
        >
        </b-input>
      </b-field>

      <div class="quizzes">
        <b-field
          label="Quizzes"
          :type="{ 'is-danger': errors.has('quizzes') }"
          :message="errors.first('quizzes')"
        >
          <b-autocomplete
            :data="formValues.quizzes.data"
            placeholder="e.g. Type something"
            :loading="formValues.quizzes.fetching"
            @typing="getQuizzesByQuestion"
            @select="addQuiz"
            name="quizzes"
            data-vv-as="Quizzes"
            v-validate="{
              required: formData.quizzes.length <= 0 ? true : false,
            }"
          >
            <template slot-scope="props">
              {{ props.option.question }}
            </template>
          </b-autocomplete>
        </b-field>

        <b-taglist>
          <b-tag
            type="is-info"
            closable
            size="is-medium"
            @close="removeQuiz(quiz, key)"
            v-for="(quiz, key) in formValues.quizzes.selectedQuizzes"
            :key="key"
          >
            {{ quiz.question }}
          </b-tag>
        </b-taglist>
      </div>
    </b-field>

    <b-field grouped>
      <b-field
        label="Cover Image"
        :type="{ 'is-danger': errors.has('coverImage') }"
        :message="errors.first('coverImage')"
      >
        <b-field v-show="!formValues.coverImage.info" class="file">
          <b-upload
            type="file"
            v-model="formValues.coverImage.file"
            name="coverImage"
            data-vv-as="coverImage"
            v-validate="!formValues.coverImage.info ? 'required' : ''"
          >
            <a class="button is-primary">
              <b-icon icon="upload"></b-icon>
              <span>Click to upload</span>
            </a>
          </b-upload>
        </b-field>
      </b-field>

      <b-field>
        <b-taglist v-if="formValues.coverImage.info">
          <b-tag
            type="is-info"
            closable
            size="is-medium"
            @close="removeCoverImage"
          >
            Remove | {{ formValues.coverImage.info.file.name }}
          </b-tag>
        </b-taglist>
      </b-field>

      <b-loading :active="formValues.coverImage.loading"></b-loading>
    </b-field>

    <b-field grouped>
      <b-field
        label="Ads"
        :type="{ 'is-danger': errors.has('image') }"
        :message="errors.first('image')"
      >
        <b-field v-show="!formValues.image.info" class="file">
          <b-upload
            type="file"
            v-model="formValues.image.file"
            name="image"
            data-vv-as="Ads"
            v-validate="!formValues.image.info ? 'required' : ''"
          >
            <a class="button is-primary">
              <b-icon icon="upload"></b-icon>
              <span>Click to upload</span>
            </a>
          </b-upload>
        </b-field>
      </b-field>

      <b-field>
        <b-taglist v-if="formValues.image.info">
          <b-tag type="is-info" closable size="is-medium" @close="removeImage">
            Remove | {{ formValues.image.info.file.name }}
          </b-tag>
        </b-taglist>
      </b-field>

      <b-loading :active="formValues.image.loading"></b-loading>
    </b-field>

    <div class="notification">
      <b-field label="Results">
        <b-tabs vertical type="is-boxed">
          <b-tab-item
            v-for="(result, key) in formData.results"
            :key="key"
            :label="`result ${key + 1}`"
          >
            <b-field
              :type="{ 'is-danger': errors.has(`result-${key}-percentage`) }"
              :message="errors.first(`result-${key}-percentage`)"
              label="Percentage"
            >
              <b-input
                type="number"
                v-model="result.percentage"
                :name="`result-${key}-percentage`"
                v-validate="'required'"
                data-vv-as="Percentage"
              >
              </b-input>
            </b-field>

            <b-field
              :type="{ 'is-danger': errors.has(`result-${key}-message`) }"
              :message="errors.first(`result-${key}-message`)"
              label="Message"
            >
              <b-input
                type="textarea"
                v-model="result.message"
                :name="`result-${key}-message`"
                v-validate="'required'"
                data-vv-as="Message"
              >
              </b-input>
            </b-field>

            <div class="buttons">
              <b-button
                @click="formData.results.splice(key, 1)"
                type="is-danger"
                icon-left="delete"
              >
                Delete
              </b-button>
            </div>
          </b-tab-item>
        </b-tabs>
      </b-field>

      <b-field v-if="formData.results.length" label="Position" class="sorting">
        <b-taglist>
          <draggable v-model="formData.results">
            <b-tag
              :style="{ cursor: 'grab' }"
              v-for="(result, key) in formData.results"
              :key="key"
              closable
              @close="formData.results.splice(key, 1)"
              type="is-primary"
            >
              {{ `${result.percentage}% | Result ${key + 1}` }}
            </b-tag>
          </draggable>
        </b-taglist>
      </b-field>

      <div class="buttons">
        <b-button
          @click="
            formData.results.push({
              percentage: 0,
              message: null,
            })
          "
          type="is-info"
          icon-left="plus"
        >
          Add more result
        </b-button>
      </div>
    </div>

    <div class="notification">
      <div class="buttons">
        <b-button
          type="is-success"
          tag="input"
          native-type="submit"
          value="Update"
        >
        </b-button>

        <b-button
          tag="router-link"
          :to="'/forms'"
          type="is-danger"
          icon-left="cancel"
          outlined
        >
          Cancel
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "FormUpdateForm",
  props: {
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
    afterRedirect: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    return {
      connection_error: false,

      formData: {
        name: null,
        quizzes: [],
        results: [],
        coverImage: null,
        image: null,

        status: null,
      },
      formValues: {
        status: [
          {
            code: true,
            name: "Enabled",
          },
          {
            code: false,
            name: "Disabled",
          },
        ],
        quizzes: {
          selectedQuizzes: [],
          data: [],
          fetching: false,
        },
        coverImage: {
          file: null,
          loading: false,
          info: null,
        },
        image: {
          file: null,
          loading: false,
          info: null,
        },
      },
    };
  },
  methods: {
    fetchQuizzes: async function (option = {}) {
      const thisCompo = this;

      const result = await this.$api
        .quizzesList({
          ...option,
          ...{
            status: true,
          },
        })
        .catch((err) => {
          if (err.response) {
            thisCompo.$buefy.toast.open({
              message: err.response.data.message,
              type: "is-danger",
              position: "is-top",
            });
          } else {
            thisCompo.$buefy.toast.open({
              message: "Connection error",
              type: "is-danger",
              position: "is-bottom",
            });
          }
        });

      return result;
    },
    getQuizzesByQuestion: async function (question) {
      if (!question) {
        return;
      }

      this.formValues.quizzes.fetching = true;

      const result = await this.fetchQuizzes({
        question: question,
      });

      this.formValues.quizzes.data = result.data.list;

      this.formValues.quizzes.fetching = false;
    },

    addQuiz: function (quiz) {
      const alreadySelected = this.formData.quizzes.find(
        (qz) => qz == quiz._id
      );

      if (!alreadySelected) {
        this.formData.quizzes.push(quiz._id);
      }
    },
    removeQuiz: function (quiz, key) {
      this.formData.quizzes.splice(key, 1);
    },
    removeCoverImage: function () {
      this.formValues.coverImage.info = null;
      this.formValues.coverImage.file = null;
      this.formData.coverImage = null;
    },
    removeImage: function () {
      this.formValues.image.info = null;
      this.formValues.image.file = null;
      this.formData.image = null;
    },
    uploadCoverImage: async function (image) {
      const thisCompo = this;

      this.formValues.coverImage.loading = true;

      const response = await this.$api
        .fileUpload({
          file: image,
          type: "image",
        })
        .catch((err) => {
          if (err.response) {
            thisCompo.$buefy.toast.open({
              message: err.response.data.message,
              type: "is-danger",
              position: "is-top",
            });
          } else {
            thisCompo.$buefy.toast.open({
              message: "Connection error",
              type: "is-danger",
              position: "is-bottom",
            });
          }
        });

      if (response) {
        this.formData.coverImage = response.data._id;

        this.formValues.coverImage.info = response.data;
      }

      this.formValues.coverImage.loading = false;
    },
    uploadImage: async function (image) {
      const thisCompo = this;

      this.formValues.image.loading = true;

      const response = await this.$api
        .fileUpload({
          file: image,
          type: "image",
        })
        .catch((err) => {
          if (err.response) {
            thisCompo.$buefy.toast.open({
              message: err.response.data.message,
              type: "is-danger",
              position: "is-top",
            });
          } else {
            thisCompo.$buefy.toast.open({
              message: "Connection error",
              type: "is-danger",
              position: "is-bottom",
            });
          }
        });

      if (response) {
        this.formData.image = response.data._id;

        this.formValues.image.info = response.data;
      }

      this.formValues.image.loading = false;
    },

    updateData: async function () {
      const thisCompo = this;

      const loading = thisCompo.$buefy.loading.open();

      const newData = {
        id: this.form._id,
        name: this.formData.name,
        quizzes: this.formData.quizzes,
        status: this.formData.status,
        results: this.formData.results,
        coverImage: [this.formData.coverImage],
        images: [this.formData.image],
      };

      const updated = await this.$api.formsUpdate(newData).catch((err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: err.response.data.message,
            type: "is-danger",
            position: "is-top",
          });
        } else {
          thisCompo.$buefy.toast.open({
            message: "Connection error",
            type: "is-danger",
            position: "is-bottom",
          });
        }

        loading.close();
      });

      if (updated) {
        loading.close();

        if (this.afterRedirect) {
          this.$router.push(this.afterRedirect);
        }

        this.$buefy.toast.open({
          message: "Successfully Updated",
          type: "is-success",
          position: "is-top",
        });
      }
    },
    submit: function () {
      const thisCompo = this;

      this.$validator.validateAll().then((result) => {
        if (result) {
          thisCompo.updateData();
        } else {
          thisCompo.$buefy.toast.open({
            message: "Form is not valid! Please check the fields.",
            type: "is-danger",
            position: "is-bottom",
          });
        }
      });
    },
  },
  watch: {
    form: {
      immediate: true,
      handler: function (newVal) {
        for (const field in newVal) {
          if (newVal[field]) {
            switch (field) {
              case "coverImage": {
                if (newVal[field].length) {
                  const [image] = newVal[field];

                  this.formData.coverImage = image._id;

                  this.formValues.coverImage.info = image;
                }

                break;
              }
              case "images": {
                if (newVal[field].length) {
                  const [image] = newVal[field];

                  this.formData.image = image._id;

                  this.formValues.image.info = image;
                }

                break;
              }
              default: {
                this.formData[field] = newVal[field];
              }
            }
          }
        }
      },
    },
    "formValues.coverImage.file": {
      handler: function (image) {
        if (image) {
          this.uploadCoverImage(image);
        }
      },
    },
    "formValues.image.file": {
      handler: function (image) {
        if (image) {
          this.uploadImage(image);
        }
      },
    },
    "formData.quizzes": {
      immediate: true,
      handler: async function (newVal) {
        if (!newVal) {
          return;
        }

        const finalQuizzes = [];
        let latestQuestion = null;
        const fetch = [];

        newVal.map((qId) => {
          const quz = this.formValues.quizzes.data.find((qz) => qz._id == qId);

          const alreadySelected = finalQuizzes.find((qz) => qz._id == qId);

          if (!alreadySelected) {
            if (quz) {
              // finalQuizzes.push(quz);
              latestQuestion = quz;
            } else {
              fetch.push(qId);
            }
          }
        });

        if (fetch.length) {
          // const result = await this.fetchQuizzes({
          //   id: fetch
          // })
          let resultData = [];

          for (const fetchId of fetch) {
            const result = await this.fetchQuizzes({
              id: fetchId,
            });

            if (result.data.total > 0) {
              resultData.push(result.data.list[0]);
            }
          }

          resultData.map((quz) => {
            const alreadySelected = finalQuizzes.find(
              (qz) => qz._id == quz._id
            );

            if (!alreadySelected) {
              finalQuizzes.push(quz);
            }
          });
        }

        if (latestQuestion) {
          finalQuizzes.push(latestQuestion);
        }

        this.formValues.quizzes.selectedQuizzes = finalQuizzes;
      },
    },
  },
};
</script>
